'use client'

import { useReportWebVitals } from 'next/web-vitals'
import { logger } from 'lib/logger'
import { useRouter } from 'next/router'
import useIsMobile from 'hooks/useIsMobile'

const CoreWebVitalsLogging = () => {
  const router = useRouter()
  const isMobile = useIsMobile()

  useReportWebVitals((metrics) => {
    // remove "entries" key as it is not needed
    const collectedMetrics = { ...metrics, entries: undefined }

    logger.info(
      {
        metrics: collectedMetrics,
        page: router.pathname,
        device: isMobile ? 'mobile' : 'desktop',
      },
      'Core Web Vitals'
    )
  })

  return null
}

export default CoreWebVitalsLogging
