import { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Alignment,
  Grid,
  Column,
  Divider,
  LocationIcon,
  TicketIcon,
  variants,
  Tag,
  Icon,
  CircleIcon,
} from '@resident-advisor/design-system'
import CmsContent from 'components/generic/cms-content'
import Date from 'components/generic/date'
import InterestedCount from 'components/generic/interested-count/InterestedCount'
import Lineup from 'components/generic/lineup'
import Link from 'components/generic/link'
import Heading from 'components/generic/heading'
import MetaLink from 'components/generic/meta-link'
import MetaText from 'components/generic/meta-text'
import { getAreaSafeName, generateUrlForAreaAndCountry } from 'lib/utils'
import { dateFormat } from 'lib/dateFormat'
import IMAGE_TYPE from 'enums/image-type'
import PLAYWRIGHT_TEST_IDS from 'enums/playwright-test-ids'
import getEventImage from 'lib/getEventImage'
import Flag from 'components/shared/flag'
import { useThemeContext } from 'hooks/useThemeContext'
import { useIntl } from 'react-intl'
import messages from 'messages/events'
import arrayHasData from 'lib/arrayHasData'
import unescape from 'lodash/unescape'
import testIds from 'enums/testIds'
import { eventRoute, eventsByAreaRoute } from 'lib/routes'
import ASPECT_RATIO, { formatAspectRatio } from 'lib/aspectRatio'
import ResponsiveImage from 'components/generic/responsive-image'
import SavedEventsButton, {
  EventSavedSourceType,
  SavedEventsButtonType,
} from 'components/user/pages/saved-events/saved-events-button/SavedEventsButton'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import EventListingCardType from 'enums/event-listing-card-type'
import BumpDto from 'interfaces/gql/BumpDto'
import ArtistDto from 'interfaces/gql/ArtistDto'
import VenueDto from 'interfaces/gql/VenueDto'
import EventPickDto from 'interfaces/gql/EventPickDto'
import ImageDto from 'interfaces/gql/ImageDto'
import Tracking, { TRACKING_EVENT } from 'scripts/tracking'
import { useInView } from 'react-intersection-observer'
import TimeSinceAnnounced from './components/TimeSinceAnnounced'

const GRID_COLUMNS = 10
const COMPACT_GRID_COLUMNS = 9

interface EventListingCardProps {
  event: {
    contentUrl: string
    title: string
    pick: EventPickDto
    venue: VenueDto
    artists: ArtistDto[]
    interestedCount: number
    date: string
    isTicketed: boolean
    flyerFront: string
    queueItEnabled: boolean
    datePosted: string
    newEventForm: boolean
    images: ImageDto[]
    isSaved: boolean
    id: string
    isInterested: boolean
    bump: BumpDto
  }
  onClick?: () => void
  showDate?: boolean
  cardType?: EventListingCardType
}

const noop = () => {
  // do nothing
}

const EventListingCard = ({
  event,
  onClick = noop,
  showDate = true,
  cardType = EventListingCardType.Default,
}: EventListingCardProps) => {
  const [ref, inView] = useInView({ triggerOnce: true })
  const bumpId = event.bump?.id

  useEffect(() => {
    if (inView && bumpId) {
      Tracking.trackMixpanel(TRACKING_EVENT.shownPromotedEventListing, {
        'Event ID': event.id,
        'Listing Type': 'Event Listings',
      })
    }
  }, [inView, bumpId, event.id])

  return (
    <Box
      data-pw-test-id={
        event.isTicketed
          ? PLAYWRIGHT_TEST_IDS.eventListingItemTicketed
          : PLAYWRIGHT_TEST_IDS.eventListingItemNonTicketed
      }
      data-testid={testIds.eventListingCard}
      ref={ref}
      onClick={onClick}
    >
      <EventDivider pick={!!event.pick} promoted={!!event.bump} />
      <EventListingGrid isTicketed={event.isTicketed}>
        <EventListingContent
          event={event}
          showDate={showDate}
          cardType={cardType}
        />
      </EventListingGrid>
    </Box>
  )
}

const EventListingGrid = ({ children, isTicketed, cardType }) => {
  const isWidget = cardType === EventListingCardType.Widget
  return (
    <Grid
      mCols={isWidget ? COMPACT_GRID_COLUMNS : GRID_COLUMNS}
      sCols={GRID_COLUMNS}
      mb={2}
      data-test-id={
        isTicketed ? testIds.ticketedEvent : testIds.nonTicketedEvent
      }
    >
      {children}
    </Grid>
  )
}

EventListingGrid.propTypes = {
  children: PropTypes.node.isRequired,
  isTicketed: PropTypes.bool,
  cardType: PropTypes.oneOf(Object.values(EventListingCardType)).isRequired,
}

const EventListingContent = ({ event, showDate, cardType }) => {
  const isWidget = cardType === EventListingCardType.Widget

  return (
    <>
      <Column sHide mSpan={isWidget ? 2 : 4}>
        <EventListingImage event={event} />
      </Column>

      <Column
        sSpan={GRID_COLUMNS}
        mSpan={isWidget ? COMPACT_GRID_COLUMNS - 2 : GRID_COLUMNS - 4}
      >
        <EventListingText
          event={event}
          showDate={showDate}
          cardType={cardType}
        />
      </Column>
    </>
  )
}

EventListingContent.propTypes = {
  event: PropTypes.object.isRequired,
  showDate: PropTypes.bool,
  cardType: PropTypes.oneOf(Object.values(EventListingCardType)).isRequired,
}

const EventListingImage = ({
  event: {
    contentUrl,
    title,
    flyerFront,
    queueItEnabled,
    images,
    newEventForm,
  },
}) => {
  const properties = {
    rt: 'fill',
    ar: formatAspectRatio(ASPECT_RATIO['16:9']),
    enlarge: true,
    quality: 50,
  }

  return (
    <Link
      variant={variants.text.heading.m}
      data-pw-test-id={PLAYWRIGHT_TEST_IDS.eventImageLink}
      {...eventRoute(contentUrl, queueItEnabled)}
    >
      <ResponsiveImage
        url={getEventImage(
          { flyerFront, images, newEventForm },
          IMAGE_TYPE.flyerFront
        )}
        aspect={ASPECT_RATIO['16:9']}
        alt={`'${title}' flyer image`}
        properties={properties}
        sizes={{ m: '33vw', l: '33vw', xl: '33vw' }}
      />
    </Link>
  )
}

EventListingImage.propTypes = {
  event: PropTypes.shape({
    contentUrl: PropTypes.string,
    title: PropTypes.string,
    flyerFront: PropTypes.string,
    queueItEnabled: PropTypes.bool,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        filename: PropTypes.string.isRequired,
        alt: PropTypes.string,
        type: PropTypes.oneOf(Object.values(IMAGE_TYPE)),
        crop: PropTypes.object,
      })
    ),
    newEventForm: PropTypes.bool,
  }).isRequired,
}

const EventListingText = ({
  event: {
    contentUrl,
    title,
    venue,
    interestedCount,
    pick,
    artists,
    date,
    isTicketed,
    queueItEnabled,
    datePosted,
    isSaved,
    id,
    isInterested,
    promoters,
    tickets,
  },
  showDate = true,
  cardType,
}) => {
  const enableSavedEvents = useFeatureSwitch(featureSwitches.enableSavedEvents)
  const isWidget = cardType === EventListingCardType.Widget

  return (
    <>
      <Alignment flexDirection="row">
        <Alignment
          flexDirection="column"
          pr={2}
          flexGrow="1"
          data-test-id={
            isTicketed ? testIds.ticketedEvent : testIds.nonTicketedEvent
          }
        >
          {showDate &&
            (datePosted && date ? (
              <EventDateAndTimeSinceAnnounced
                date={date}
                datePosted={datePosted}
              />
            ) : (
              date && <EventDate date={date} />
            ))}
          {title && contentUrl && (
            <EventHeading
              contentUrl={contentUrl}
              queueItEnabled={queueItEnabled}
            >
              {unescape(title)}
            </EventHeading>
          )}
        </Alignment>
        {!isWidget && enableSavedEvents && (
          <Alignment>
            <SavedEventsButton
              trackingData={{
                venue,
                artists,
                promoters,
                tickets,
                isTicketed,
                source: EventSavedSourceType.EventListingsCard,
              }}
              eventId={id}
              isInterested={isInterested}
              interestedCount={interestedCount}
              isSaved={isSaved}
              type={SavedEventsButtonType.EventCardIcon}
            />
          </Alignment>
        )}
      </Alignment>

      {arrayHasData(artists) && (
        <Alignment justifyContent="space-between">
          {!isWidget && (
            <Lineup
              data-test-id={testIds.artistsLineup}
              artists={artists}
              mt={2}
              color="primary"
            />
          )}
          {interestedCount > 0 && !venue && (
            <InterestedCount count={interestedCount} />
          )}
        </Alignment>
      )}
      {showEventDetailBar({ venue, isTicketed, interestedCount, artists }) && (
        <Alignment justifyContent="space-between" mt={2}>
          {(venue || isTicketed) && (
            <Alignment alignItems="center">
              {venue?.area && <EventArea area={venue.area} />}
              {venue?.name && (
                <EventVenue
                  name={venue.name}
                  contentUrl={venue?.live ? venue.contentUrl : undefined}
                />
              )}
              {isTicketed && (
                <MetaLink
                  Icon={TicketIcon}
                  color="secondary"
                  iconColor="secondary"
                  href={`${contentUrl}#tickets`}
                  text={<TicketText />}
                />
              )}
            </Alignment>
          )}

          {!isWidget && (
            <InterestedCount
              data-test-id={testIds.interestedCount}
              count={interestedCount}
            />
          )}
        </Alignment>
      )}
      {pick && <PickBlurb>{pick.blurb}</PickBlurb>}
    </>
  )
}

EventListingText.propTypes = {
  event: PropTypes.shape({
    contentUrl: PropTypes.string,
    title: PropTypes.string,
    pick: PropTypes.object,
    venue: PropTypes.object,
    artists: PropTypes.array,
    interestedCount: PropTypes.number,
    date: PropTypes.string,
    isTicketed: PropTypes.bool,
    queueItEnabled: PropTypes.bool,
    datePosted: PropTypes.string,
    isSaved: PropTypes.bool,
    isInterested: PropTypes.bool,
    id: PropTypes.string,
    promoters: PropTypes.array,
    tickets: PropTypes.array,
  }).isRequired,
  showDate: PropTypes.bool,
  cardType: PropTypes.oneOf(Object.values(EventListingCardType)).isRequired,
}

const showEventDetailBar = ({ venue, isTicketed, interestedCount, artists }) =>
  venue || isTicketed || !!(interestedCount && !artists)

const TicketText = () => {
  const intl = useIntl()

  return (
    <>
      <Box as="span" display={{ m: 'none' }}>
        RA
      </Box>
      <Box as="span" display={{ s: 'none', m: 'inline' }}>
        {intl.formatMessage(messages.raTickets)}
      </Box>
    </>
  )
}

const EventHeading = ({ children, contentUrl, queueItEnabled }) => (
  <Heading data-pw-test-id={PLAYWRIGHT_TEST_IDS.eventTitle}>
    <Link
      data-pw-test-id={PLAYWRIGHT_TEST_IDS.eventTitleLink}
      variant={variants.text.heading.m}
      data-test-id={testIds.eventListingHeading}
      {...eventRoute(contentUrl, queueItEnabled)}
    >
      {children}
    </Link>
  </Heading>
)

EventHeading.propTypes = {
  children: PropTypes.node,
  contentUrl: PropTypes.string.isRequired,
  queueItEnabled: PropTypes.bool,
}

const EventVenue = ({ name, contentUrl }) => {
  if (contentUrl) {
    return (
      <MetaLink
        Icon={LocationIcon}
        text={name}
        href={contentUrl}
        mr={2}
        linkProps={{
          'data-pw-test-id': PLAYWRIGHT_TEST_IDS.eventVenueLink,
        }}
      />
    )
  }
  return (
    <MetaText
      Icon={LocationIcon}
      text={name}
      mr={2}
      textProps={{
        'data-pw-test-id': PLAYWRIGHT_TEST_IDS.eventVenueLink,
      }}
    />
  )
}

EventVenue.propTypes = {
  name: PropTypes.string.isRequired,
  contentUrl: PropTypes.string,
}

const EventDate = ({ date }) => (
  <Date
    color="secondary"
    date={date}
    format={dateFormat(date)}
    variant={variants.text.heading.s}
  />
)

EventDate.propTypes = {
  date: PropTypes.string.isRequired,
}

const EventDateAndTimeSinceAnnounced = ({ date, datePosted }) => (
  <Alignment flexDirection="row" alignItems="center">
    <EventDate date={date} />
    <Icon size={8} mx={2} Component={CircleIcon} color="secondary" />
    <TimeSinceAnnounced datePosted={datePosted} />
  </Alignment>
)

EventDateAndTimeSinceAnnounced.propTypes = {
  date: PropTypes.string.isRequired,
  datePosted: PropTypes.string.isRequired,
}

const EventArea = ({ area }) => (
  <>
    <Flag size={18} urlCode={area.country.urlCode} />
    <Link
      ml={1}
      mr={2}
      color="primary"
      variant={variants.text.label}
      {...eventsByAreaRoute(generateUrlForAreaAndCountry('/events', area))}
    >
      {getAreaSafeName(area, area.country)}
    </Link>
  </>
)

EventArea.propTypes = {
  area: PropTypes.shape({
    country: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      urlCode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

const EventListingTag = ({
  message,
  variant = variants.tag.primary,
}: EventListingTagProps) => <Tag variant={variant}>{message}</Tag>

type EventListingTagProps = {
  message: string
  variant?: string
}

const EventDivider = ({ pick, promoted }) => {
  const theme = useThemeContext()
  const intl = useIntl()

  return (
    <>
      <Divider
        mb={pick ? 0 : 2}
        borderColor={pick ? 'accent' : theme.divider.defaultColor}
      />
      {pick && (
        <Box mb={1}>
          <EventListingTag message={intl.formatMessage(messages.raPick)} />
        </Box>
      )}
      {promoted && (
        <Box mb={1} mt={pick ? 1 : 2}>
          <EventListingTag
            message={intl.formatMessage(messages.promoted)}
            variant={variants.tag.tertiary}
          />
        </Box>
      )}
    </>
  )
}

EventDivider.propTypes = {
  pick: PropTypes.bool,
  promoted: PropTypes.bool,
}

const PickBlurb = ({ children }) => (
  <CmsContent color="secondary" pt={2} content={children} />
)

PickBlurb.propTypes = {
  children: PropTypes.node.isRequired,
}

EventListingCard.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    contentUrl: PropTypes.string,
    title: PropTypes.string,
    pick: PropTypes.object,
    venue: PropTypes.object,
    artists: PropTypes.array,
    promoters: PropTypes.array,
    tickets: PropTypes.array,
    interestedCount: PropTypes.number,
    date: PropTypes.string,
    isTicketed: PropTypes.bool,
    flyerFront: PropTypes.string,
    queueItEnabled: PropTypes.bool,
    bump: PropTypes.object,
  }).isRequired,
  onClick: PropTypes.func,
  cardType: PropTypes.oneOf(Object.values(EventListingCardType)),
}

export { EventListingText, EventHeading, EventListingImage }
export default EventListingCard
