const PLAYWRIGHT_TEST_IDS = Object.freeze({
  eventImageLink: 'event-image-link',
  eventTitleLink: 'event-title-link',
  eventTitle: 'event-title',
  eventHeader: 'event-header',
  eventVenueLink: 'event-venue-link',
  popularEvents: 'popular-events',
  eventListing: 'event-listing',
  popularEventItem: 'popular-event-item',
  eventListingItemTicketed: 'event-listing-item-ticketed',
  eventListingItemNonTicketed: 'event-listing-item-nonticketed',
  ticketsIframe: (id: string) => `tickets-iframe-${id}`,
  eventListingItem: 'event-listing-item',
  globalNav: 'global-nav',
  myAccount: 'my-account',
  loginUsername: 'login-username',
  loginPassword: 'login-password',
  loginSubmit: 'login-submit',
  authenticatedPerson: 'authenticated-person',
  cornerNav: 'corner-nav',
  musicCard: 'music-card',
  newsSection: 'news-section',
  searchIcon: 'search-icon',
  searchInput: 'search-input',
  sourceSaveArtistIconButton: 'source-save-artist-icon-button',
  sourceSavedArtistListMore: 'source-saved-artist-list-more',
  navDropDown: 'nav-drop-down',
  mobileNavModal: 'mobile-nav-modal',
  mobileNavModalUser: 'mobile-nav-modal-user',
  beaconButton: 'beacon-button',
  beaconHelpButton: 'beacon-help-button',
  paymentUserForm: 'payment-user-form',
  ticketTierPrice: 'ticket-tier-price',
  ticketTierFee: 'ticket-tier-fee',
  orderSummaryTotalPrice: 'order-summary-total-price',
  basketTicketItem: 'basket-ticket-item',
  openNavModal: 'open-nav-modal',
  ticketFilter: 'ticket-filter',
  ticketPassOption: 'ticket-pass-option',
  ticketFilterButton: 'ticket-filter-button',
  confirmOrderCloseButton: 'confirm-order-modal-close-button',
})

type PlaywrightTestIds =
  typeof PLAYWRIGHT_TEST_IDS[keyof typeof PLAYWRIGHT_TEST_IDS]

export type { PlaywrightTestIds }
export default PLAYWRIGHT_TEST_IDS
